import { FormCreate } from '../../components';
const CreateView = () => {

    return (
        <>
            <FormCreate />

        </>
    )
}

export default CreateView;